/*
   Color Palette

   These colors shouldn't be used in a component directly.
   Always assign them a functional variable name before use.

   Tool for color name lookup: http://chir.ag/projects/name-that-color
**/


/*
   Forwood Style Guide Color Palette

   Comments indicate legacy color variables to replace with Forwood Palette colors
**/
$forwood-blue-1: #2ED2E9;
$forwood-blue-2: #17A7FE;
$forwood-blue-3: #0173B4;
$forwood-blue-4: #002A74;

$forwood-gray-1: #DCDCDC;
$forwood-gray-2: #B3B3B3;
$forwood-gray-3: #656565;
$forwood-gray-4: #343434;

$white: #ffffff;
$black: #000000;
$dodger-blue: #1890FF;
$dodger-blue-light: #40A9FF;
$pomegranate: #F52222;
$mercury: #E8E8E8;
$alto: #d9d9d9;
$athens-grey: #f0f2f5;

/*
   Functional variables

   Components should refer to their functional name rather than the color name.
   These should always refer to a palette color from above
**/

$primary: $forwood-blue-3;
$highlight: $forwood-blue-2;
$secondary: $forwood-gray-1;
$error: $pomegranate;

// Structure view
$structure-matched-node-color: $forwood-gray-3;
$structure-unmatched-node-color: $forwood-gray-2;
